// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Global } from "src/app/services/global";
export const environment = {
  production: false,
  version: '1.0.23',
  // Key
  KEY: '234NutrecoCo4562',
  Global,

  //APIS TIPO DE TRABAJO
  API_CONSULTAJOBTYPE: 'ConsultasGenerales/ConsultaJobType',
  API_REGISTROJOBTYPE: 'RegisterGeneral/RegisterJobType',
  API_UPDATEJOBTYPE: 'RegisterGeneral/UpdateJobType',
  API_DELETEJOBTYPE: 'RegisterGeneral/DeleteJobType',
  API_ACTIVARJOBTYPE: 'RegisterGeneral/ActiveJobType',
  API_CONSULTAJOBTYPEACTIVE: 'ConsultasGenerales/ConsultaJobTypeActive',

  //APIS PLANTA
  API_CONSULTAPLANTA: 'ConsultasGenerales/ConsultaPlantas',
  API_REGISTROPLANTA: 'RegisterGeneral/RegisterPlanta',
  API_UPDATEPLANTA:   'RegisterGeneral/UpdatePlanta',
  API_DELETEPLANTA:   'RegisterGeneral/DeletePlanta',
  API_ACTIVARPLANTA:  'RegisterGeneral/ActivarPlanta',

   //APIS AREA DE PLANTA
   API_CONSULTASECCIONPLANTA: 'ConsultasGenerales/ConsultaSeccionPlanta',
   API_REGISTROSECCIONPLANTA: 'RegisterGeneral/RegisterSeccionPlanta',
   API_UPDATESECCIONPLANTA: 'RegisterGeneral/UpdateSeccionPlanta',
   API_DELETESECCIONPLANTA: 'RegisterGeneral/DeleteSeccionPlanta',
   API_ACTIVARSECCIONPLANTA: 'RegisterGeneral/ActiveSeccionPlanta',

   //APIS PARAMETROS GLOBALES
  API_CONPARAM: 'ConsultasGenerales/ConsultaParam',
  API_REGISTROPARAM: 'RegisterGeneral/RegisterParam',
  API_REGISTERPARAMDETAIL: 'RegisterGeneral/RegisterParamDetails',
  API_UPDATEPARAMGENERIC: 'RegisterGeneral/UpdateParam',
  API_CONDETAILS: 'ConsultasGenerales/ConsultaParamDetail',
  API_UPDATEPARAMDETAIL: 'RegisterGeneral/UpdateParamDetails',
  API_DELETEPARAMGENERIC: 'RegisterGeneral/deactivateParam',
  API_ACTIVEPARAMGENERIC: 'RegisterGeneral/ActiveParam',
  API_ACTIVEPARAMGENERICDETAIL: 'RegisterGeneral/activeParamDetails',
  API_DELETEPARAMGENERICDETAIL: 'RegisterGeneral/deactivateParamDetails',

  //APIS PARAMETROS DOCUMENTOS
  API_CONSUDOCUJOB: 'ConsultasGenerales/ConsultaDocuJobType',
  API_REGISTERDOCUJOB: 'RegisterGeneral/RegisterParamDocuJob',
  API_UPDATEDOCUJOB: 'RegisterGeneral/UpdateParamDocuJob',
  API_DELETEDOCUJOB: 'RegisterGeneral/deleteParamDocuJob',
  API_ACTIVEDOCUJOB: 'RegisterGeneral/activeParamDocuJob',
  API_CONSUDOCU: 'ConsultasGenerales/ConsultaDocumento',
  API_REGISTERDOCU: 'RegisterGeneral/RegisterDocu',
  API_UPDATEDOCU: 'RegisterGeneral/UpdateParamDocu',
  API_DELETEDOCU: 'RegisterGeneral/deleteParamDocu',
  API_ACTIVEDOCU: 'RegisterGeneral/activeParamDocu',

   //API TIPIFICACION FALTA
   API_CONSULTATYPEFALTA:'ConsultasGenerales/ConsultaParamFoul',
   API_REGISTERTYPEFOUL:'RegisterGeneral/RegisterTypeFouls',
   API_UPDATETYPEFOUL:'RegisterGeneral/UpdateTypefouls',
   API_DESACTIVETYPEFOUL:'RegisterGeneral/desactivetypefoul',
   API_ACTIVETYPEFOUL:'RegisterGeneral/activetypefoul',
   API_CONSULTAFALTADETAIL:'ConsultasGenerales/ConsultaFoul',
   API_REGISTERFOUL:'RegisterGeneral/RegisterFouls',
   API_UPDATEFOUL:'RegisterGeneral/Updatefouls',
   API_DESACTIVEFOUL:'RegisterGeneral/desactivefoul',
   API_ACTIVEFOUL:'RegisterGeneral/activefoul',
   API_SANCION:'ConsultasGenerales/ConsultaSancion',
   API_REGISTERSANCION:'RegisterGeneral/RegisterSancion',
   API_UPDATESANCION:'RegisterGeneral/Updatesancion',
   API_DESACTIVESANCION:'RegisterGeneral/desactivesancion',
   API_ACTIVESANCION:'RegisterGeneral/activesancion',

  //APIS SUBCONTRATISTA
  API_CONSULTASUBCOMPANIE: 'CompaniesConsulta/SubCompanySelect',
  API_CONSULTASUBCOMPANIEJOB: 'CompaniesConsulta/SubCompanyJobSelect',
  API_REGISTERJOBSUBCOM: 'RegisterSubCompany/RegisterSubCompanyJob',
  API_UPDATESUBJOBCOM: 'RegisterSubCompany/UpdateSubCoJob',
  API_ACTIVESUBJOBCOM: 'RegisterSubCompany/activeSubCoJob',
  API_DESACTIVESUBJOBCOM: 'RegisterSubCompany/deactivateSubCoJob',
  API_CREATESUBCOM: 'RegisterSubCompany/SubCompanyCreate',
  API_DESACTIVESUBCOM: 'RegisterSubCompany/deactivateSubCo',
  API_ACTIVESUBCOM: 'RegisterSubCompany/activeSubCo',
  API_CONSULTASUBCOMPANIECI: 'CompaniesConsulta/SubCompanySelectCi',
  API_UPDATESUBCOMPANY: 'RegisterSubCompany/SubCompanyUpdate',

    //APIS COMPANIES ADMIN
    API_REGISTEREMP: 'RegisterCompany/RegisterCompanyAdmin',
    API_CONSULTACOMPANIES: 'CompaniesConsulta/CompanySelect',
    API_CONSULTAEMP: 'ConsultasGenerales/ConsultaCompania',
    API_UPDATEEMP: 'RegisterCompany/UpdateCompani',
    API_DESATEEMP: 'RegisterCompany/deactivateCo',
    API_ACTIVETEEMP: 'RegisterCompany/activateCo',
    API_SUBCOMPANIEBYCOMPIE: 'CompaniesConsulta/SubCompanyByIDCompany',
    API_UPDATECOMPAEMP: 'RegisterCompany/UpdateCompaniemp',
    API_CONSDOCUMENEMP: 'CompaniesConsulta/ConsultaDocumentobyEmp',

      //API DE COMUNICACION
  API_CONSTIPOCOMUNICADO: 'ConsultasGenerales/ConsultaTipoInfo',
  API_REGISTERTYPEINFO: 'RegisterGeneral/RegisterTypeInfo',
  API_UPDATETYPEINFO: 'RegisterGeneral/UpdateTypeInfo',
  API_DESACTIVETYPEINFO: 'RegisterGeneral/desactiveTypeInfo',
  API_ACTIVETYPEINFO: 'RegisterGeneral/activeTypeInfo',
  API_CONSINFO: 'ConsultasGenerales/ConsultaInfo',
  API_CONSULINFOALL: 'ConsultasGenerales/ConsultaInfoAll',
  API_REGISTERINFO:'RegisterGeneral/RegisterInfo',
  API_UPDATEINFO:'RegisterGeneral/UpdateInfo',
  API_DESACTIVEINFO:'RegisterGeneral/desactiveInfo',
  API_ACTIVEINFO:'RegisterGeneral/activeInfo',

  //API DE PREGUNTAS
  //TIPO DE PREGUNTAS
  API_CONSTIPOPREGUNTA: 'ConsultasGenerales/ConsultaTipoPregunta',
  API_REGTIPOPREGUNTA: 'RegisterGeneral/Registertipopregunta',
  API_UPDATEPREGUNTA: 'RegisterGeneral/Updatetipopregunta',
  API_DESACTIVEPREGUNTA: 'RegisterGeneral/desactivepregunta',
  API_ACTIVEPREGUNTA: 'RegisterGeneral/activepregunta',

  API_CONSPREGUNTA: 'ConsultasGenerales/ConsultaPregunta',
  API_REGPREGUNTA: 'RegisterGeneral/Registerpregunta',
  API_UPDATEPREGUNTAS: 'RegisterGeneral/Updatepregunta',
  API_DESACTIVEPREGUNTAS: 'RegisterGeneral/desactivepreguntas',
  API_ACTIVEPREGUNTAS: 'RegisterGeneral/activepreguntas',
  API_CONSPREGUNTADETALLE: 'ConsultasGenerales/ConsultaDetallePregunta',
  API_REGIPREGUNTADETALLE: 'RegisterGeneral/RegisterDetallePregunta',
  API_UPDATEPREGUNTADETALLE: 'RegisterGeneral/Updatepreguntadetalle',
  API_DESACTIVEPREGUNTADETALLE: 'RegisterGeneral/desapreguntadetalle',
  API_ACTIVEPREGUNTADETALLE: 'RegisterGeneral/activepreguntadetalle',

  //APIS EVALUACION
  API_CONSEVALUACION: 'ConsultasGenerales/ConsultaParamEvaluacion',
  API_REGEVALUACION: 'Evaluation/Registerparamevaluacion',
  API_UPDATEEVALUACION: 'Evaluation/Updateevaluacion',
  API_DESACTEVALUACION: 'RegisterGeneral/desaevaluacion',
  API_ACTIVEEVALUACION: 'RegisterGeneral/activeevaluacion',
  API_UPDATESTTUSEVALUATION:"Evaluation/UpdateStatus",



};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
