import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/authentication/login.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { environment } from 'src/environments/environment';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';


const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_GET_EMPLOYEE_INTERNAL_ALL = environment.Global.API_GET_EMPLOYEE_INTERNAL_ALL;
const API_USER_SYSTEM_UPDATE = environment.Global.API_USER_SYSTEM_UPDATE;

@Component({
  selector: 'app-maintuser',
  templateUrl: './maintuser.component.html',
  styleUrls: ['./maintuser.component.css']
})
export class MaintuserComponent extends BaseCrudComponent implements OnInit {
  userinfo:any;
  listUsers = [];
  listEmployees = [];
  empleados = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public auth: LoginService,
    public translate: TranslateService,
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.pageSize = 8;
    this.search_fields = ['UserId','Username'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getList();
  }

  public getList() {
    this.loadingList = true;
    let url = API_USER_SYSTEM_GET_ALL;
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rawdata = x.val;
          this.rows = rs;
          this.listUsers = x.val;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
        }else {
          this.rows = [];
          return;
        }
      }else {
        this.rows = [];
        return;
      }
    });
  }


  public async openUpdateModal(modal, row) {
    this.u_obj = row;
    this.u_obj['name_last_name'] = row.FirstName + " " + row.LastName;
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, windowClass:"myCustomModalClass"});
    this.empleados = [];
    await this.getEmployees();
  }


  public async getEmployees() {
    let url = API_GET_EMPLOYEE_INTERNAL_ALL;
    await this.srv.getCall(url).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.listEmployees = x.val;
          let lista = []
          if ( this.u_obj['EmployeeId'] != null) { // no tiene empleado
            if (this.u_obj['EmployeeId'].includes(',')) {
              lista = this.u_obj['EmployeeId'].split(',');
            } else {
              lista = [this.u_obj['EmployeeId']];
            }
            let l = [];
            this.listEmployees.forEach( empleado => {
              if (lista.includes((empleado.EmployeeId).toString())) {
                l.push(empleado.EmployeeId);
              }
            })
            this.empleados = l;
          }
        }else {
          this.listEmployees = [];
          return;
        }
      }else {
        this.listEmployees = [];
        return;
      }
    });
  }

  public updateItem(modal) {
    this.isUpdating = true;
    let url = API_USER_SYSTEM_UPDATE;
    let codeEmployee = "";
    if (this.empleados.length > 0) {
      codeEmployee = this.empleados.toString()
    } else {
      codeEmployee = null;
    }
    let new_obj= {
      UserId : this.u_obj['UserId'],
      EmployeeId : codeEmployee
    }

    // console.log(new_obj)

    this.srv.postCall(url, new_obj).subscribe(data => {
      if (data.success) {
        this.isUpdating = false;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.u_obj = {};
        this.empleados = [];
        this.getList();
        this.closeModal(modal)
      }
    },catchError =>{
      this.isUpdating = false;
      Swal.fire(catchError.message, '', 'error');
    });
  }
}
